*, html {
  scroll-behavior: smooth !important;
}

.App{
  font-family:sans-serif;
  line-height:1.5;
}

.app-logo{
  border-radius: 3px;
}

.clickable-icons{
  /* background-color: white ; */
  background-image: linear-gradient(rgb(232, 232, 232), rgb(125, 123, 123));
  padding: 10px 10px 5px 10px;
  border-radius: 15px;
  box-shadow: 0px 5px 10px 1px rgb(58, 58, 58);
  /* box-shadow: 0px 5px 10px 1px rgb(106, 106, 106); */
  
}

.clickable-icons:hover{
  cursor: pointer;

  -webkit-filter: brightness(.5);
  filter: brightness(.5);
  box-shadow: 0px 5px 5px 5px rgb(47, 47, 47) !important;

}

.divider-dec-tec-color{
  color:#12749b;
  background-color:#12749b; 
  border-top-color:#12749b;
}

/* ---------------------MUI CSS --------------------- */

.MuiPaper-root{
  background-color: transparent !important;
  /* background-color: rgb(48, 48, 48) !important; */
  box-shadow: none !important;
}
.MuiContainer-root{
  padding: 10px !important;
}
.MuiBox-root{
  justify-content: right !important;
}
.MuiBox-root button{
  text-transform: none;
  padding: 0px 35px 0px 35px;
}
.MuiList-root{
  background-color: white;
}
.MuiList-root li:hover{
  background-color: rgb(199, 198, 198);
}
.MuiButtonBase-root svg{
  font-size: 2.5rem;
}
/* --------------------------------------------------- */

.header-content {
  color: white;
  font-size: 1.3vmax;
  height: 100vh;

  box-shadow: 5px 5px 20px rgb(156, 156, 156);

  background-image: url("./images/08/Dec-Tec-DIY-Camouflage-Tan-promo-image-scaled.jpg");
  background-position: 30% 80%;
  /* background-size: cover; */
}
.title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.3max;
}

.header-body {
  padding: 40px;
  text-align: center;
}

.description-section {
  /* font-family:"Open Sans" , san-seriff; */
  text-align: center;
  font-size: 1.0vmax;

  margin-top: 2px; /* needed to not cause border color overlap over SVG */

  width: auto;
  background-color: #d8d8d8;
}

.feature-products-section {
  font-size: 0.9vmax;
  text-align: center;
  /* padding: 0px 130px 0px 130px; */
  padding: 20px;
  margin-top: -25px;
}
.products {
  display: flex;
  gap: 60px;

  justify-content: center;
}

.products .product-items p {
  height: 8vmax;
  max-width: 400px;
  margin: auto;
  text-align: center;

}
.pattern {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 60px;
}

.resp-container-horizontal {
  display: flex;
  flex-direction: row;
}

.customer-documentation {
  padding-top: 10px;
  background-image: url("./images/08/bg-customer-documentation.png");
  background-size: cover;
  padding-bottom: 45px;
  background-position: 50%;
}
.customer-documentation-items div {
  width: 20vw;
  text-align: center;
  font-size: 0.9vmax;
}
.customer-documentation-items {
  display: flex;
  gap: 60px;

  justify-content: center;
}

/* Section Break*/
hr {
  border-top: 5px solid #19242f;
  border-radius: 5px;
}
.download-button {
  background-image: none !important;
  background-color: #12749b !important;
  color: white !important;
  transition: 0.3s;
}
.download-button:hover {
  background-color: #0e5b79 !important;
  box-shadow: 0px 0px 15px rgb(156, 156, 156);
}
.footer {
  display: flex;
  background-color: #19242f;
  justify-content: space-between;
}
.email:hover {
  text-decoration: underline;
  color: rgb(64, 75, 233) !important;
}
.email:link{
  color: rgb(205, 230, 255);
  
}
.product-items p{
  padding-bottom: 15px;
}


/* Handles different screens */
@media screen and (max-width: 1200px) {
  .resp-container-horizontal {
    flex-direction: column !important;
  }
  .header-content{
    height: unset;
    text-align: center;
  }
  .header-body{
    font-size: 1rem;
  }
  .products .product-items p li {
    width: 100%;
  }
  .customer-documentation .resp-container-horizontal div {
    margin: auto;
    width: 40%;
  }
  .product-items {
    width: 100%;
  }
  .footer {
    width: 100%;
    text-align: center;
  }
  .customer-documentation{
    background-size: 250rem;
  }
  .store-icons-wrapper{
    flex-direction: column !important;
  }
  .description-section{
    font-size: 1.6vmax;
  }
  .feature-products-section{
    font-size: 1.5vmax;
  }
  .customer-documentation-items div{
    font-size: 1.5vmax;
    width: 280px !important; 

  }
}
